$(document).ready(function(){

	FastClick.attach(document.body);

    $('#headerNaviButton').click(function(ev){
        ev.preventDefault();
        $('#headerNavi').toggleClass('naviOpened');
    });

	var mySwiper = new Swiper('.swiper-container', {
		speed: 400,
		autoplay: 5000,
		autoplayDisableOnInteraction: false,
		spaceBetween: 1,
		pagination: '.swiper-pagination',
		paginationClickable: true,
		loop: true,
		loopAdditionalSlides: 1
	});

	mySwiper.on('slideChangeStart', function () {
		var slideIndex = $('.swiper-container .swiper-slide-active').attr('data-swiper-slide-index');
		$('#sliderBullets a').removeClass('current');
		$('#sliderBullets a').eq(slideIndex).addClass('current');
	});

	$('#sliderBullets a').click(function(ev){
		ev.preventDefault();
		var bulletPos = $('#sliderBullets a').index($(this));
		$('.swiper-pagination span').eq(bulletPos).trigger('click');
	})

    $(window).resize(function(){
		$('#headerNavi').removeClass('naviOpened');
	});

    var $pswp = $('.pswp')[0];
		$('#mapZoom a').click(function(event) {
			event.preventDefault();
			
			var items = [];
				
					var $href   = $(this).attr('href'),
						$size   = $(this).data('size').split('x'),
						$width  = $size[0],
						$height = $size[1];
					var item = {
						src : $href,
						w   : $width,
						h   : $height
					}
	 
					items.push(item);
				
			
			var options = {
				index: 0,
				bgOpacity: 0.75,
				showHideOpacity: true,
				captionEl: true,
			}
			
			// Initialize PhotoSwipe
			var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
			lightBox.init();
		});
	
});